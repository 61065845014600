import React from "react";
// import { Link } from "gatsby"

import { Box, Heading, Text, Container } from "@chakra-ui/react";

// import { MdCheckCircle, MdSettings } from "@chakra-ui/icons";
import { FiAward } from "react-icons/fi";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import Map from "../components/mapComponent";

const LegendPage = () => (
  <Layout>
    <SEO title="The legend of the bearbat" />

    {/* <Stack w="80%" mx="auto" direction={["column", "row"]} spacing="2rem"> */}
    <Container maxW="xl" centerContent>
      <Box borderRadius="xl" padding="4" bg="gray.100" maxW="3xl">
        <Heading>The forgotten legend...</Heading>
        <Text>
          It is a long forgotten story. That might or might not have been told
          by GPT-3.
          <br />
          <br />
          Some say that it can fly over land and is said to have crashed into a
          lake, killing 100,000 deer, bobcats, bobcats and other denizens of the
          beautiful South Lake Union. But I bet you a T-Dog who sees an arrow in
          its eye doesn't think this is reality. What actually happens in the
          woods off Lake Superior Mountain now? As I cruise down the hill, I
          keep seeing dragonflies come flying overhead. <br />
          <br />
          If you get my drift lesson on just how spectacular steppe deer
          landings are, you might be on to something: The story goes like this:
          I was headed to the northwest shore of the lake just before the start
          of the weekend. <br /> We figured it was best to surf the river for a
          day trip to the lakes and rivers, but suddenly I was seeing a horse
          with a massive wire whip. I took my wire fishing pole and watched as
          the giant, wire-like creatures began making its way south along the
          lake's edge. When I spotted this horse, all I had to do was pull him
          across the bridge and he would vanish like a ghost. <br />
          <br />
          The creature was now on to me and I could see its eyes glowing blue
          and moving like dusk to the south horizon. I tried to clear the air
          for good visibility, but the sun was getting so hot that the view was
          dim. I decided to follow guide, which wasn't really safe using your
          cell phone lens. That should give enough of me the ability to find the
          dog. <br />
          <br />
          But we had to paddle a little further south to get some visibility.
          The great part of the weekend was spent getting back in the car and
          driving down the canyon. We made a pit stop in the middle of the lake
          where we filmed the rest of the climb up the canyon roads. <br />
          <br />
          Two sticks of weed sat almost parallel on a canyon road that was a
          stone's throw from the river below. With help from a friend, a grizzly
          had started pull-offs and the bear had dropped to it's knees. A half
          mile down the trail, Susan came upon this wild goat. When she stood at
          the edge of a log, she saw the bear, covered in fur, her ears and
          head. <br />
          <br />
          It looked monstrous now but she could see its eyes glow red as the
          predator wandered down the log. I saw that, he was sick. What?! A
          bear! He sputtered at us from a line of cameras, his favorite beater
          taking in the latest footage... well, I wish no one else was around.
          The grizzly bear was pummeling Susan and trapping her down yet again.
          In the chaos below, the bear released Susan and disappeared behind the
          rope line. I wasn't sure how, but it was able to follow me uphill into
          the valley but Susan did as she was held captive by the predator I was
          riding.
          <br /> At the same time, it released its prey twice, once in the
          middle of the lake and once even back to my right. I tried to use my
          phone, but I was on my way back to the forest. I don't even know why I
          was watching the trail. I was going to try and see if the wolves could
          take me where I needed to go.
          <br />
          <br />
          But I caught a bite to some red bear hide and was bitten once in the
          back. I watched the hawk dart away and headed down the trail the next
          day. I continued to follow the wolf with a bite to the left ear. It
          took a few moments to catch my breath and everything was fine. I was
          saved and lucky not to make it back to the lake. <br />
          The rest of the day was spent riding along and documenting the park
          along with the trail cameras. We made a stop off the path to let Susan
          know where we were and she excitedly trotted along until she emerged
          and was back to me in the darkness. The rest of the trail took the
          form of the trail switchblades, the two pack soldiers and the hawk.
          <br />
          <br /> I jumped in, soared over the marked trail and ran up the
          lakefront to take photos with the hawk. It was around this time that I
          had the chance to photograph some of the area's most beautiful birds
          and nature reserves. I ran hard at hiking in the forest and watching
          the hawk's feathered friends take in the beautiful displays. During
          one of the rare encounters that I had with bird watchers, I could tell
          that a pack of rabid birds had roamed the area and before the pack
          came to their senses, a pack of lions surrounded by two big heads of
          deer. <br />
          <br />
          Since the deer and the hawk were considered sights best suited for a
          pack of omnivores, I finally found out that, for my money, they had
          both been saving the area by turning away the big head birds.
          <br /> As I walked down the path, I could tell that the hawk was
          looking for a good place to push food and shelter.
        </Text>
      </Box>
    </Container>
    {/* </Stack> */}

    {/* <Link to="/page-2/">Go to page 2</Link> <br /> */}
  </Layout>
);

export default LegendPage;
